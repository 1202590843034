
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "9f49cf7e-e89f-496c-a059-76cb5ca9bdfc"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/builds/dpa-gmbh/produktionssysteme/rubix/rubix-monitor-frontend/app.config.ts"

export default /* #__PURE__ */ defuFn(cfg0, inlineConfig)
